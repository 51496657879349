.green {
  background: green;
  color: white;
}

.red {
  background: red;
  color: white;
}

.yellow {
  background: yellow;
  color: black;
}
