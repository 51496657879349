#root {
  width: 100%;
  height: 100%;
  display: flex;
  /*flex: 1 1 auto;*/
  flex-direction: column;
  /*order: 2;*/
  /*display: flex;*/
}

body {
  min-height: 100%;
  overflow: hidden;
  margin: 0px; /* removes default style */
  display: flex; /* enables flex content for its children */
  flex-direction: column;
}
